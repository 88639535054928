import React from "react";
import { StaticImage } from "gatsby-plugin-image";

//styles
import * as styles from "../../styles/templates/itineraryTemplates.module.scss";
import { Container, Row, Col } from "react-bootstrap";

const IslandSpin = () => {
  return (
    <div className={styles.main}>
      <Container>
        <h3 className="text-center">Island Spin by Tripfusion</h3>
        <h3 className="text-center">06 Nights / 07 Days</h3>
        <div className="text-center my-md-5 my-2">
          <StaticImage
            src="../../images/itinerary/island-spin/table.jpg"
            alt="table"
          />

          <p className="mt-2">
            B – Breakfast L – Lunch D – Dinner AI – All Inclusive
          </p>
        </div>
        <h3>Itinerary Highlights</h3>
        <ul>
          <li>Senadiriyagama Village Tour</li>
          <li>Dambulla Cave Temple</li>
          <li>Sigiriya Rock Citadel</li>
          <li>Polonnaruwa Ancient City</li>
          <li>Kandy tooth relic temple</li>
          <li>Horton Plain</li>
          <li>Yala National Park (1 Time Safari )</li>
        </ul>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/island-spin/day-1.jpg"
              alt="day2"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 01 : Airport - Dambulla</h3>
              <p>Meals: B - -</p>
              <p>Fly Colombo to xxx n on xxx, departing xxx HRS on</p>
              <p>Ayubowan! Welcome to Sri Lanka</p>
              <p>
                On arrival after the immigration process and claiming your
                luggage, our representative along with the designated chauffeur
                guide will take you through to the exit and will escort you to
                the car. You will then be transferred to Dambulla.
              </p>
              <p>
                <i>Airport – Dambulla</i>
                <div>
                  <i>Travel Distance: 160 km</i>
                </div>
                <div>
                  <i>Approximate travel time: 3 hours & 45 minutes</i>
                </div>
              </p>

              <p>
                On your way to Dambulla visit Dambulla Cave Temple.
                <div>
                  <i>Approximately Excursion Time: 02 ½ Hrs</i>
                </div>
              </p>
              <p>
                Passing Matale as one reaches the city of Dambulla lays a hub
                which has been treasured by many kings since the 1st century
                B.C. Today, an icon in the face of Sri Lanka Dambulla Rock
                Temple houses some of the most unique drawings in magnificently
                constructed 5 cave temples flanking the golden temple of
                Dambulla. Observe how exquisitely cave paintings are done along
                walls that shelter 150 statues of Lord Buddha. As you enter the
                retreat look straight up to the gigantic Buddha statue
                glittering in gold over a land which today houses an important
                junction in the country's economic and cultural uplifting.
              </p>
              <p>
                Check in at the hotel and rest of the morning to be spent at
                leisure recovering from the long journey.
              </p>
              <p>
                <b>
                  Overnight stay at Elephant Stables – Dambulla
                  <div>Room Category: Luxury Chalet</div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/island-spin/day-2.jpg"
              alt="day2"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 02: Senadiriyagama Village – Polonnaruwa</h3>
              <p>Meals: B L D</p>
              <p>After breakfast, you will proceed to Senadirigama Village</p>
              <p>
                <i>Hotel – Senadirigama Village</i>
              </p>
              <p>
                <i>Travel Distance: 15 km</i>
              </p>
              <p>
                <i>
                  Approximate travel time: 20 minutes & approximate excursion
                  time 3 hours
                </i>
              </p>
              <p>
                This morning after a scrumptious breakfast you will set out on
                an exciting excursion to Senadiriyagama, a beautiful little
                village located within a 30 minute drive from Sigiriya. Your
                first visit is to the village temple with a history spanning
                over 300 years. You will be greeted by the village folk with
                lotus flowers which are customarily believed to represent
                purity, spiritual awakening and faithfulness. The short tractor
                ride along the gravel road through the village that leads up to
                the temple is an experience in itself as you will see the
                smiling faces of villagers engaging in their daily routines and
                children walking to school by the roadside in their pristine
                white uniforms.
              </p>
              <p>
                Once you arrive at the temple, you will light oil lamps, incense
                sticks and offer flowers by the Sacred Bo Tree and stupa. You
                will then walk towards “Budu Geya” - the main house with
                Senadhira, a local farmer who will fervently explain the history
                of the temple and the village relating to stories about how
                Senadiriyagama temple was once used as a resting camp for troops
                during the period the land was ruled by the kings. After a
                “Pirith” chanting, you will be blessed by the temple monk for
                the onward journey by tying a white string commonly known by the
                locals as a “Pirith Noola”.
              </p>
              <p>
                Thereafter you will walk along the river bund towards the pier
                to experience a catamaran ride on the Senadiriyagama Lake. If
                you are lucky you will have a glimpse of the locals fishing by
                the lake and maybe even purchase the catch of the day! Once you
                arrive on the other side of the lake you will take a short walk
                towards the “Wadiya” - a mud hut, after being served a cool king
                coconut to beat the hot sun, where a delicious Sri Lankan meal
                with local delicacies and tropical fruits is prepared for you to
                indulge in. Upon completion, a waiting tuk tuk will transport
                you back to the vehicle.
              </p>
              <p>
                <b>Afternoon visit Polonnaruwa ruins</b>
              </p>
              <p>
                <b>Approximate excursion time: 2-3 hours</b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr style={{ color: "white" }} />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/island-spin/day-2.5.jpg"
              alt="day2.5"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="ps-sm-3">
              {/* <StaticImage
              src="../../images/itinerary/island-spin/ultra.png"
              alt="day2.5"
              className="my-3"
              // style={{ height: "1100px", width: "100%" }}
            /> */}
              <p>
                Polonnaruwa - is the 2nd capital of Sri Lanka. Built in the 11th
                and 12th centuries A.D, and is a world heritage site. Here you
                can see the ruins of the Royal Palace, the Gal Viharaya (3
                splendid statues of the Buddha in upright, sedentary and
                recumbent postures carved out of rock). The audience hall, the
                lotus bath, the Polonnaruwa statue (12th century) and the
                Parakrama Samudra tank (lake) built by king Parakramabahu the
                great. There are also ruins of famous places of worship such as
                the Shiva temple, the Lankatileka, the Watadage, the Galpotha,
                the Kiri Vihare Dagoba and the remains of a former temple of the
                tooth.
              </p>
              <p>
                On complexion transfer back to Dambulla – spend the rest of the
                evening at leisure.
              </p>
              <p>
                <b>
                  Overnight stay at Elephant Stables – Dambulla
                  <div>Room Category: Luxury Chalet</div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/island-spin/day-3.jpg"
              alt="day3"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 03 : Dambulla – Sigiriya Rock – Kandy</h3>
              <p>Meals: B - D</p>
              <p>
                After breakfast, you will check – out and proceed to Sigiriya
                Rock Citadel.
              </p>
              <p>
                The 5th century rock citadel of King Kasyapa and World Heritage
                Site - Sigiriya - doesn't only boasts of ancient Sri Lankan
                engineering & urban planning supremacy but also acclaims to be
                one of the finest monuments of art & culture. Step through the
                gigantic 'Lion Paws' - overlooking the symmetrical royal gardens
                below - to comprehend the absolute splendor that once dominated
                this rock fortress. Surrounded by ramparts & moats the Lion Rock
                - resembling the mythological 'City of Gods' - is coated by
                frescoes that relate to Gupta style paintings found in AOctta
                caves of India. Walk in the shade of an eminent 'mirror wall'
                embracing the Western face of Sigiriya representing an artistic
                hundred meters laminated with graffiti. Wander into the
                well-fabricated museum downstairs for an epic journey of
                yesteryears to realize how the citadel floated above citizens
                with the castle, ponds and irrigation systems that pumped water
                right onto its summit with an ingenious hydraulic system
                describing a colossal masterpiece of the great king which
                remained a wonder in Asia for centuries.
              </p>
              <p>
                <i>
                  <div>Hotel – Sigiriya Rock Citadel</div>
                  <div>Travel Distance: 16 km</div>
                  <div>Approximate travel time: 25 minutes</div>
                  <div>Approximately Excursion Time: 02 ½ Hrs</div>
                </i>
              </p>
              <p>On completion transfer to Hotel in Kandy.</p>
              <p>
                <i>
                  Dambulla - Kandy
                  <div>
                    <i>Travel Distance: 90 km</i>
                  </div>
                  <div>
                    <i>Approximate travel time: 2 Hrs. & 30 minutes</i>
                  </div>
                </i>
              </p>
              <p>
                Arrival and check in at the hotel in Kandy and rest of the
                afternoon to be spent at leisure.
              </p>
              <p>
                <b>
                  Overnight stay at Theva Residency
                  <div>Room Category: Deluxe Room </div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/island-spin/day-4.jpg"
              alt="day4"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 04 : Kandy – Kandy temple Visit – Nuwara Eliya</h3>
              <p>Meals: B - D</p>
              <p>
                After breakfast proceed to to witness the Kandy tooth relic
                temple.
              </p>
              <p>
                Last ruled by King Sri Wickrama Rajasinghe - before it was
                brought under the British rule in 1815, Kandy - the World
                Heritage Site & last royal capital of Sri Lankan kings - crafts
                an array of culture, history and heritage in the minds of
                visitors across the globe. Experience an ancient Kandyan legacy
                engraved around its crown jewel, the temple of the sacred tooth
                relic of Lord Buddha. A stroll across this small lakeside town -
                cradled among the misty hills - will feature the Kandy City
                Centre - bundled with latest and world class brands, bazaar, an
                arts & crafts centre, as well as a gem museum & lapidary. Drive
                towards Victoria Golf Course, undoubtedly one of the best golf
                courses in the world for a sweep blended with class and style.
                Or walk in color as you glide through fresh fruits and
                vegetables in the Kandy market. Fine tune your evening by
                stepping to the beat of traditional music & drumming amplified
                by a cultural show enhancing the rich and vibrant culture of the
                Wonder of Asia, Sri Lanka.
              </p>
              <p>
                <i>Approximately Excursion Time: - 1 hour</i>
              </p>
              <p>On completion transfer to</p>
              <p>
                <i>Kandy Temple - Hotel</i>
                <div>
                  <i>Travel Distance: 4 km</i>
                </div>
                <div>
                  <i>Approximate travel time: 15 Minutes</i>
                </div>
              </p>
              <p>
                <b>
                  Dinner & Overnight stay at Ferncliff Boutique Bungalow
                  <div>Room Category: Luxury Room</div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/island-spin/day-5.jpg"
              alt="day5"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 05: Nuwara Eliya – Horton Plains – Yala</h3>
              <p>Meals: B - -</p>
              <p>
                Leave early morning with packed breakfast to Horton Plains for a
                trekking excursion.
              </p>
              <p>
                <i>Nuwara Eliya – Horton Plains</i>
                <div>
                  <i>Travel Distance: 30 km</i>
                </div>
                <div>
                  <i>Approximate travel time: 1 ½ Hrs</i>
                </div>
                <div>
                  <i>Approximate Excursion Time: 4 hrs</i>
                </div>
              </p>
              <p>
                Horton Plains, UNESCO World Heritage Site & Eco-tourism hideout
                in the heart of the isle, painted with unending stretches of
                green pastures marks one of the healthiest wet montane evergreen
                forests in Sri Lanka. With an altitude above 7000ft, the plateau
                sweeps to a robust 3169 hectares sheltering flora and fauna
                varieties that may be found nowhere else on earth. As the
                tributary of Belihul Oya - Bakers Falls - thunders down rocks
                head off the beaten track - across frost-covered grass - to feel
                the icy-cold wind against your face. You may even gaze down
                World's End, a head-spinning 3700ft drop towards the Southern
                end or drive carefully through herds of sambar deer, shy to
                human touch & birdlife chirping across the plains. If you're
                cautious enough you may be greeted by the sights of a grunting
                wild boar, a pouncing fishing cat, otters or even the
                'Spotted-Ghost that haunts the plains', the Sri Lankan Leopard!
              </p>
              <p>
                Return to the hotel upon completion, after you will check out &
                proceed to Yala.
              </p>
              <p>
                <i>Nuwara Eliya – Yala</i>
                <div>
                  <i>Travel Distance: 170 km</i>
                </div>
                <div>
                  <i>Approximate travel time: 4 hours & 30 minutes</i>
                </div>
              </p>
              <p>
                <b>
                  Dinner & Overnight stay at Cinnamon Wild
                  <div>Room Category: Jungle Chalet</div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/island-spin/day-6.jpg"
              alt="day6"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 06: Yala – Ahangama</h3>
              <p>Meals: B - -</p>
              <p>
                This morning you will have an early wake-up call at 5:15AM. You
                will then depart at dawn for another join-in 4WD jeep wildlife
                safari into the national park. Among the many wondrous sites and
                experiences today our main goal: a glimpse of the magnificent
                yet elusive leopard.
              </p>
              <p>
                <i>Approximate Excursion Time: 3 hrs</i>
              </p>
              <p>
                Yala National park is where you can see Sri Lankan wild animals
                in their natural state. Inside the park you will come across
                wild boar, deer, leopards, elephants, bears, peacocks,
                flamingoes and many more animals roaming freely as nature
                intended them to live. The elephants gather at the waterholes
                for drinking and you may watch them bathing their young and
                immersing themselves in the river. They can also be sighted at
                close range sometimes. You may also see leopards sunning
                themselves on rocks in the park. Herds of deer roam at will
                keeping a wary eye on the predators. Peacocks flaunting their
                colourful feathers strut around and flamingoes are a
                breathtaking sight. You will find Yala a delightful place to
                survey our wild animals.
              </p>
              <p>
                Return to the hotel for a full breakfast, check out and then
                proceed to Koggala. Arrival and check in at the hotel.
              </p>
              <p>
                <i>Yala – Ahangama</i>
                <div>
                  <i>Travel Distance: 170 km</i>
                </div>
                <div>
                  <i>Approximate travel time: 4 hours</i>
                </div>
              </p>
              <p>Rest of the evening to be spent at leisure.</p>
              <p>
                <b>
                  Overnight stay at Cantaloupe House
                  <div>Room Category: Standard Room</div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/island-spin/day-7.jpg"
              alt="day7"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 07: Ahangama - Airport</h3>
              <p>Meals: B - D</p>
              <p>
                Today you will leave to the airport in time for a departure
                flight.
              </p>
              <p>
                <i>Ahangama - Airport</i>
                <div>
                  <i>Travel Distance: 170 km</i>
                </div>
                <div>
                  <i>Approximate travel time: 2 hours & 20 minutes</i>
                </div>
              </p>

              <p>
                <i>Fly Colombo to xxx n on xxx, departing xxx HRS on</i>
              </p>
              <h3>End of Services</h3>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default IslandSpin;
