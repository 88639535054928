import * as React from 'react';
import Breadcrumbs from '../components/breadcrumb';
import { useParams } from 'react-router-dom';

//Components
import Layout from '../components/layout';
import Title from '../components/title';

//Pages
import FortsAndFrescos from './itineraryTemplates/fortsAndFrescos';
import FloraAndFauna from './itineraryTemplates/floraAndFauna';
import IslandSpin from './itineraryTemplates/islandSpin';
import ZephyrAndWaves from './itineraryTemplates/zephyrAndWaves';
import ShanthiAndPrana from './itineraryTemplates/shanthiAndPrana';

//styles
import { Container } from 'react-bootstrap';

const itineraryData = [
  {
    slug: 'forts-and-frescos',
    title: 'Forts and Frescos',
  },
  {
    slug: 'flora-and-fauna',
    title: 'Flora & Fauna',
  },
  {
    slug: 'island-spin',
    title: 'Island Spin',
  },
  {
    slug: 'shanthi-and-prana',
    title: 'Shanthi & Prana',
  },
  {
    slug: 'zephyr-and-waves',
    title: 'Zephyr & Waves',
  },
];

const Itinerary = ({ pageContext }) => {
  const slug = pageContext.slug;

  const selectedItinerary = itineraryData.find(
    (itinerary) => itinerary.slug === slug
  );

  if (!selectedItinerary) {
    return <div>Itinerary not found</div>;
  }

  return (
    <>
      <Layout>
        <Title picture title={selectedItinerary.title} />
        {/* Add SEO Here */}
        <Container>
          <Breadcrumbs
            links={[
              {
                text: 'itinerary',
                link: '/itinerary',
              },
              {
                text: selectedItinerary.title,
                link: `/itinerary/${selectedItinerary.slug}`,
              },
            ]}
          />

          {selectedItinerary.slug === 'forts-and-frescos' && FortsAndFrescos()}
          {selectedItinerary.slug === 'island-spin' && IslandSpin()}
          {selectedItinerary.slug === 'shanthi-and-prana' && ShanthiAndPrana()}
          {selectedItinerary.slug === 'zephyr-and-waves' && ZephyrAndWaves()}
          {selectedItinerary.slug === 'flora-and-fauna' && FloraAndFauna()}
        </Container>
      </Layout>
    </>
  );
};

export default Itinerary;
