import React from "react";
import { StaticImage } from "gatsby-plugin-image";

//styles
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "../../styles/templates/itineraryTemplates.module.scss";

const ZephyrAndWaves = () => {
  return (
    <div className={styles.main}>
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/zephyr-and-waves/day-1.jpg"
              className="h-100 w-100"
              alt="Day-1"
            />
          </Col>
          <Col>
            <div className="ps-sm-3">
              <h3 className="text-center mt-sm-5 mt-2">
                Zephyr & Waves by Tripfusion
              </h3>
              <h3 className="text-center">08 Nights / 09 Days</h3>
              <div className="text-center mt-2">
                <StaticImage
                  src="../../images/itinerary/zephyr-and-waves/table.jpg"
                  alt="table"
                />

                <p className="mt-2">
                  B – Breakfast L – Lunch D – Dinner AI – All Inclusive
                </p>
              </div>
              <h3 className="mt-3">Itinerary Highlights</h3>
              <ul>
                <li>Panoramic city tour of Colombo</li>
                <li>Horton Plains</li>
                <li>Visit a tea plantation</li>
                <li>Yala National Park</li>
              </ul>
              <h3 className="pt-sm-5 pt-2">Day 01: Airport - Colombo</h3>
              <p>Meals: - - -</p>
              <p>
                <i>Fly xxx to Colombo on xx, arriving xxx HRS on xxx</i>
              </p>
              <p>Ayubowan! Welcome to Sri Lanka</p>
              <p>
                On arrival after the immigration process and claiming your
                luggage, our representative along with the designated
                chauffeur/national guide will take you through to the exit and
                will escort you to your vehicle. VIP arrival service will be
                booked at the airport to ensure a smooth arrival for you. You
                will then be transferred to Colombo.
              </p>
              <p>
                <div>Airport – Colombo</div>
                <div>Travel Distance: 32 km</div>
                <div>Approximate travel time: 45 Minutes</div>
              </p>
              <p>
                Arrival and check in at the hotel and rest of the day to be
                spent at leisure recovering from the long journey.
              </p>
              <p>
                <b>
                  <div>Overnight stay at Uga Residence, Colombo</div>
                  <div>Room Category: Park Suite</div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/zephyr-and-waves/day-2.jpg"
              className="h-100 w-100"
              alt="Day-2"
            />
          </Col>
          <Col>
            <div className="ps-sm-3">
              {/* <StaticImage
              src="../../images/itinerary/zephyr-and-waves/ultra.png"
              alt="ultra"
              className="my-2 my-sm-3"
              // style={{ height: "1100px", width: "100%" }}
            /> */}
              <h3 className="pt-sm-5 pt-2">Day 02: Colombo – Nuwara Eliya</h3>
              <p>Meals: B L D</p>

              <p>
                After breakfast at the hotel, check out from the hotel and leave
                to Nuwara Eliya on a scenic drive en-route visiting a tea
                plantation
              </p>

              <p>
                <div>Colombo – Nuwara Eliya</div>
                <div>Travel Distance: 184 km</div>
                <div>Approximate travel time: 6 hours</div>
              </p>
              <p>
                Nuwara Eliya meaning "the city of dawn" is the town at the
                highest elevation (about 2000m above sea level) in Sri Lanka and
                its tallest peak, Piduruthalagala (2555m) could be seen from
                here. The city established by the British in the nineteenth
                century, and known as "Little England" and is a popular holiday
                resort for Sri Lankans & tourists due to its cool climate and
                quaint colonial feel with its little bungalows surrounded by
                hedgerows. Nuwara Eliya is also the heart of the tea country and
                you will visit a plantation and a factory en route to witness
                how the famous "Ceylon Tea" is brewed.
              </p>
              <p>
                Arrival and check in at the hotel and rest of the day to be
                spent at leisure recovering from the long journey.
              </p>
              <p>
                <b>
                  <div>Overnight stay at Goatfell, Nuwara Eliya</div>
                  <div>Room Category: Concordia Bedroom</div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/zephyr-and-waves/day-3.jpg"
              className="h-100 w-100"
              alt="Day-3"
            />
          </Col>
          <Col>
            <div className="ps-sm-3">
              <h3 className="pt-sm-5 pt-2">
                Day 03: Nuwara Eliya – Horton Plains
              </h3>
              <p>Meals: B L D</p>

              <p>
                Leave early morning to Horton Plains National Park with packed
                breakfast for a trekking excursion.
              </p>

              <p>
                <i>
                  <div>Hotel – Horton Plains</div>
                  <div>Travel Distance: 45 km</div>
                  <div>Approximate travel time: 01 Hour 45 Minutes</div>
                  <div>Approximate Excursion time: 03 Hours</div>
                </i>
              </p>
              <p>
                Perched on the very edge of the hill country midway between
                Nuwara Eliya and Haputale, Horton Plains Natural Park covers a
                wild stretch of bleak, high altitude moorland bounded at its
                southern edge of the hill country, including the famous 'World
                End", where the escarpment falls sheer for the best part of a
                kilometer to the lowlands below. You will feel the cool, wet
                climate which has fostered the growth of a distinctive range of
                flora, including various rhododendrous, bamboos, tree ferns and
                many endemic species of plants, making the Plains an area of
                great biological value. The Plains' wildlife attractions are
                relatively modest. Leopards still visit the park, but one has to
                be incredibly luckily to see one. The parks most visible
                residents are its herds of deer and bear-faced monkeys. The
                plains are also one of the best places in the island for bird
                watching and an excellent places to see Montana endemics such as
                the dull-blue flycatcher; bush warbler, whistling thrush,
                yellow-eared bulbul, as well as orange minarets.
              </p>
              <p>
                On completion you will return to the hotel in time for lunch and
                have the rest of the afternoon spent at leisure.
              </p>
              <p>
                <b>
                  <div>Overnight stay at Goatfell, Nuwara Eliya</div>
                  <div>Room Category: Concordia Bedroom</div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/zephyr-and-waves/day-4.jpg"
              className="h-100 w-100"
              alt="Day-4"
            />
          </Col>
          <Col>
            <div className="ps-sm-3">
              <h3 className="pt-sm-5 pt-2">Day 04: Nuwara Eliya – Yala</h3>
              <p>Meals: All Inclusive</p>

              <p>
                Today, after breakfast at the hotel, you will leave to Yala
                located in the deep south of Sri Lanka.
              </p>

              <p>
                <i>
                  <div>Nuwara Eliya – Yala</div>
                  <div>Travel Distance: 180 km</div>
                  <div>Approximate travel time: 5 hours</div>
                </i>
              </p>
              <p>
                Standard check-in time at the hotel is 1300hrs. After lunch, you
                will proceed on a private jeep safari at the Yala National Park
                by 1430 HRS.
              </p>
              <p>
                Yala National park is where you can see Sri Lankan wild animals
                in their natural state. Inside the park you will come across
                wild boar, deer, leopards, elephants, bears, peacocks,
                flamingoes and many more animals roaming freely as nature
                intended them to live. The elephants gather at the waterholes
                for drinking and you may watch them bathing their young and
                immersing themselves in the river. They can also be sighted at
                close range sometimes. You may also see leopards sunning
                themselves on rocks in the park. Herds of deer roam at will
                keeping a wary eye on the predators. Peacocks flaunting their
                colourful feathers strut around and flamingoes are a
                breathtaking sight. You will find Yala a delightful place to
                survey our wild animals.
              </p>
              <p>
                Return to the hotel and rest of the evening to be spent at
                leisure.
              </p>
              <p>
                <b>
                  <div>Overnight stay at Chena Huts, Yala</div>
                  <div>Room Category: Cabin with Pool </div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/zephyr-and-waves/day-5.jpg"
              className="h-100 w-100"
              alt="Day-5"
            />
          </Col>
          <Col>
            <div className="ps-sm-3">
              <h3 className="pt-sm-5 pt-2">Day 05: Yala</h3>
              <p>Meals: All Inclusive</p>

              <p>
                This morning you will have an early wake-up call at 4:45AM. You
                will then depart at dawn for another join-in 4WD jeep wildlife
                safari into the national park. Among the many wondrous sites and
                experiences today our main goal: a glimpse of the magnificent
                yet elusive leopard.
              </p>
              <p>
                Return to the hotel around 10:00 for a full breakfast. Rest of
                the day to be spent at leisure. You will engage in another
                afternoon safari after lunch today.
              </p>

              <p>
                <b>
                  <div>Overnight stay at Chena Huts, Yala</div>
                  <div>Room Category: Cabin with Pool </div>
                </b>
              </p>

              <h3 className="pt-sm-5 pt-2">Day 06: Yala – Tangalle</h3>
              <p>Meals: B - -</p>

              <p>
                This morning after a leisurely breakfast, check out from the
                hotel and leave for Tangalle for a short beach stay.
              </p>

              <p>
                <i>
                  <div>Yala – Tangalle</div>
                  <div>Travel Distance: 83 km</div>
                  <div>Approximate travel time: 2 hours</div>
                </i>
              </p>

              <p>
                <b>
                  <div>Overnight stay at Amanwella, Tangalle</div>
                  <div>Room Category: Garden Pool Suite</div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/zephyr-and-waves/day-7-8.jpg"
              className="h-100 w-100"
              alt="Day-7&8"
            />
            {/* <StaticImage
              src="../../images/itinerary/zephyr-and-waves/ultra.png"
              alt="ultra"
              className="my-2 my-sm-3"
              // style={{ height: "1100px", width: "100%" }}
            /> */}
          </Col>
          <Col>
            <div className="ps-sm-3">
              <h3 className="pt-sm-5 pt-2">Day 07 & 08: Tangalle</h3>
              <p>Meals: B - -</p>

              <p>Spend the full day at leisure</p>

              <p>
                <b>
                  <div>Overnight stay at Amanwella, Tangalle</div>
                  <div>Room Category: Garden Pool Suite</div>
                </b>
              </p>

              <h3 className="pt-sm-5 pt-2">Day 09: Tangalle – Airport</h3>
              <p>Meals: B - -</p>

              <p>
                After breakfast at the hotel you will leave to the airport in
                time for the departure flight.
              </p>

              <p>
                <i>
                  <div>Tangalle - Airport</div>
                  <div>Travel Distance: 37 km</div>
                  <div>Approximate travel time: 45 minutes</div>
                </i>
              </p>

              <h3>End of services</h3>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ZephyrAndWaves;
