import React from "react";
import { StaticImage } from "gatsby-plugin-image";

//styles
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "../../styles/templates/itineraryTemplates.module.scss";

const FortsAndFrescos = () => {
  return (
    <div className={styles.main}>
      <Container>
        <h3 className="text-center">Forts & Frescos by Tripfusion</h3>
        <h3 className="text-center">11 Nights / 12 Days</h3>
        <div className="text-center my-md-5 my-2">
          <StaticImage
            src="../../images/itinerary/forts-and-frescos/table.jpg"
            alt="table"
          />
          <p className="mt-2">
            B – Breakfast L – Lunch D – Dinner AI – All Inclusive
          </p>
        </div>
        <h3>Itinerary Highlights</h3>
        <ul>
          <li>Dambulla Cave temple </li>
          <li>Sigiriya Rock Citadel</li>
          <li>Minneriya National Park (Jeep Safari)</li>
          <li>Senadiriyagama Village Tour</li>
          <li>Temple of the Tooth Relic</li>
          <li>Cultural Dance Show</li>
          <li>Three Temple Loop</li>
          <li>Ella Sightseen</li>
          <li>Yala National Park (Jeep Safari)</li>
          <li>Galle City Tour</li>
        </ul>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/forts-and-frescos/kotugoda.jpg"
              className="h-100 w-100"
              alt="kotugoda"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 01: Airport - Kotugoda</h3>
              <p>Meals: - - D</p>
              <p>Ayubowan! Welcome to Sri Lanka</p>
              <p>
                On arrival as you exit the aircraft, our representative will
                welcome you and escort you to the lounge after immigration
                process, where you can freshen up and enjoy a quick cup of tea
                while he/she claims your luggage. Our representative along with
                the designated chauffeur guide will then take you through to the
                exit and will escort you to the car. You will then be
                transferred to your hotel in Kotugoda for check-in and a
                well-deserved rest after your long journey.
              </p>
              <p>
                <div>Airport – Kotugoda</div>
                <div>Travel Distance: 10 km</div>
                <div>Approximate travel time: 20 Minutes</div>
              </p>
              <p>Check in to the Hotel, Rest & Relax</p>
              <p>
                <b>
                  <div>Overnight stay at The Wallawwa Colombo</div>
                  <div>Room Category: Wallawwa Room</div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/forts-and-frescos/sigiriya.jpg"
              alt="sigiriya"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 02: Kotugoda - Dambulla Cave Temple -Kandalama</h3>
              <p>Meals: B – D</p>
              <p>
                Breakfast at Hotel and leave to Kandalama, en-route visit
                Dambulla Cave Temple
              </p>
              <p>
                <div>Kotugoda – Dambulla</div>
                <div>Travel Distance: 130 km</div>
                <div>Approximate travel time: 03 Hours and 20 Minutes</div>
                <div>Approximate excursion time: 02 Hours </div>
              </p>
              <p>
                In the city of Dambulla lies a hub which has been treasured by
                many kings since the 1st century B.C. Today, an icon in the face
                of Sri Lanka, Dambulla Rock Temple houses some of the most
                unique drawings in magnificently constructed 5 cave temples
                flanking the golden temple of Dambulla. Observe how exquisitely
                cave paintings are done along walls that shelter 150 statues of
                Lord Buddha. As you enter the retreat, look straight up to the
                gigantic Buddha statue glittering in gold over a land, which
                today houses an important junction in the country's economic and
                cultural uplifting.
              </p>
              <p>Upon completion transfer to your hotel in Kandalama</p>
              <p>
                <div>Dambulla - Kandalama</div>
                <div>Travel Distance: 13 km</div>
                <div>Approximate travel time: 30 Minutes</div>
              </p>
              <p>
                <div>
                  <b>Overnight stay at Heritance Kandalama, Kandalama</b>
                </div>
                <div>
                  <b>Room Category: Superior Room</b>
                </div>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/forts-and-frescos/minneriya.jpg"
              alt="minneriya"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 03: Sigiriya Rock Citadel - Minneriya Safari</h3>
              <p>Meals: B – D</p>
              <p>
                After breakfast at the hotel proceed to Sigiriya Rock Citadel.
              </p>
              <p>
                <div>Kandalama - Sigiriya</div>
                <div>Travel Distance: 30 km</div>
                <div>Approximate travel time: 45 minutes</div>
                <div>Approximate excursion time: 03 Hours </div>
              </p>
              <p>
                After an ancient prince killed his father to claim the throne,
                he was forced to flee to his pleasure palace and convert it into
                a fortress to guard against his opposition. The spectacular
                citadel of Sigiriya built on a giant rock rises sheer and
                impregnable out of the plains below. This remarkable 5th century
                archaeological site is made unforgettable by its dramatic
                setting. A moat, rampart, extensive gardens including the
                renowned water gardens ring the base of the rock and the
                frescoes of the 'Heavenly Maidens' of Sigiriya. As you descend
                you’ll stop for a rest in a shaded location where your porter
                will have laid out mats and various tasty Sri Lankan delicacies!{" "}
              </p>
              <p>
                Note: Please note that to reach the top of Sigiriya Rock, there
                are nearly 1,200 stone steps up and then down. Anybody in
                reasonably good condition will be able to venture to the top of
                Sigiriya Rock and it's certainly worth the effort, however
                important to know these little details before embarking on this
                outing.
              </p>
              <p>
                In the afternoon after Lunch proceed to Minneriya National Park
              </p>
              <p>
                <div>Sigiriya – Minneriya</div>
                <div>Travel Distance: 20 km</div>
                <div>Approximate travel time: 30 Minutes</div>
                <div>Approximate Excursion time: 04 Hours</div>
              </p>
              <p>
                Minneriya - do a safari of the minneriya national park. The
                extent of this national park is about 8,889 hect. There are wet
                lands in this national park which have international importance,
                animal and plant species which are plenty in dry zone are found
                in this park. Wild elephants, various amphibians, local and
                immigrant bird species are plenty in this park. about hundred
                and sixty species of birds, 09 amphibians, 25 reptile species,
                26 fish species and more than 78 butterfly species have been
                found in this area. Upon completion proceed to the hotel.
              </p>
              <p>
                <div>
                  <b>Overnight stay at Heritance Kandalama, Kandalama</b>
                </div>
                <div>
                  <b>Room Category: Superior Room</b>
                </div>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/forts-and-frescos/senadiriyagama.jpg"
              alt="senadiriyagama"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 04: Senadiriyagama Village Tour</h3>
              <p>Meals: B – D</p>
              <p>
                This morning after a scrumptious breakfast you will set out on
                an exciting excursion to Senadiriyagama,
              </p>
              <p>
                <div>Kandalama – Senadiriyagma </div>
                <div>Travel Distance: 40 km</div>
                <div>Approximate travel time: 01 Hour</div>
                <div>Approximate excursion time: 03 Hours </div>
              </p>
              <p>
                A beautiful little village located within a 30 minute drive from
                Sigiriya. Your first visit is to the village temple with a
                history spanning over 300 years. You will be greeted by the
                village folk with lotus flowers which are customarily believed
                to represent purity, spiritual awakening and faithfulness. The
                short tractor ride along the gravel road through the village
                that leads up to the temple is an experience in itself as you
                will see the smiling faces of villagers engaging in their daily
                routines and children walking to school by the roadside in their
                pristine white uniforms.
              </p>
              <p>
                Once you arrive at the temple, you will light oil lamps, incense
                sticks and offer flowers by the Sacred Bo Tree and stupa. You
                will then walk towards “Budu Geya” - the main house with
                Senadhira, a local farmer who will fervently explain the history
                of the temple and the village relating to stories about how
                Senadiriyagama temple was once used as a resting camp for troops
                during the period the land was ruled by the kings. After a
                “Pirith” chanting, you will be blessed by the temple monk for
                the onward journey by tying a white string commonly known by the
                locals as a “Pirith Noola”.
              </p>
              <p>
                Thereafter you will walk along the river bund towards the pier
                to experience a catamaran ride on the Senadiriyagama Lake. If
                you are lucky you will have a glimpse of the locals fishing by
                the lake and maybe even purchase the catch of the day! Once you
                arrive on the other side of the lake you will take a short walk
                towards the “Wadiya” - a mud hut, after being served a cool king
                coconut to beat the hot sun, where a delicious Sri Lankan meal
                with local delicacies and tropical fruits is prepared for you to
                indulge in. Upon completion, a waiting tuk tuk will transport
                you back to the vehicle
              </p>
              <p>
                Upon completion return back to Hotel and rest of the day spent
                at leisure
              </p>
              <p>
                <div>
                  <b>Overnight stay at Heritance Kandalama, Kandalama</b>
                </div>
                <div>
                  <b>Room Category: Superior Room</b>
                </div>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/forts-and-frescos/kandy.jpg"
              alt="kandy"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>
                Day 05: Kandalama - Temple of Tooth – Cultural Dance Show –
                Kandy
              </h3>
              <p>Meals: B - -</p>
              <p>
                Breakfast at Hotel and leave to Kandy, en-route visit to Temple
                of Tooth Relic & in the evening Cultural Dance Show.
              </p>
              <p>
                <div>Kandalama - Kandy</div> <div>Travel Distance: 90 km</div>
                <div>Approximate travel time: 02 Hour & 30 Minutes </div>
                <div>Approximate excursion time: 02 Hours & 30 Minutes</div>
              </p>
              <p>
                <span>
                  <b>Temple of Tooth</b>
                </span>{" "}
                - Last ruled by King Sri Wickram Rajasinghe - before it was
                brought under the British rule in 1815, Kandy - the World
                Heritage Site & last royal capital of Sri Lankan kings - crafts
                an array of culture, history and heritage in the minds of
                visitors across the globe. Experience an ancient Kandyan legacy
                engraved around its crown jewel, the temple of the sacred tooth
                relic of Lord Buddha. A stroll across this small lakeside town -
                cradled among the misty hills - will feature the Kandy City
                Centre - bundled with latest and world class brands, bazaar, an
                arts & crafts centre, as well as a gem museum & lapidary. Drive
                towards Victoria Golf Course, undoubtedly one of the best golf
                courses in the world for a sweep blended with class and style.
                Or walk in color as you glide through fresh fruits and
                vegetables in the Kandy market. Fine tune your evening by
                stepping to the beat of traditional music & drumming amplified
                by a cultural show enhancing the rich and vibrant culture of the
                Wonder of Asia, Sri Lanka.
              </p>
              <p>
                <span>
                  <b>Cultural Dance Show</b>
                </span>
                - which showcase the rich cultural heritage of the Kandyan
                Kingdom. These shows essentially comprise various styles of
                Kandyan dance, and are quite popular with visitors to the Hill
                Capital.
              </p>
              <p>
                <b>Overnight stay at The Radh, Kandy</b>
                <div>
                  <b>Room Category: Deluxe Room</b>
                </div>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/forts-and-frescos/three-temple-loop.jpg"
              alt="Three Temple Loop"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 06: Three Temple Loop</h3>
              <p>Meals: B - -</p>
              <p>Breakfast at Hotel and leave to visit the Tree Temple Loop</p>
              <p>Approximate travel time: 02 Hours</p>
              <p>
                Three temples, all offering different stories to capture your
                imagination. Start at Gadaladeniya and step into the 12th
                century. In this temple you will find out about the influence
                that Buddhism has on Hinduism and vice versa. Here notice the
                grandeur of the architecture, the incredible size of the Buddha
                statue and the remarkable artistry in the detailing of the
                artwork. Enjoy a tuk tuk ride through paddy fields and reach the
                second temple, Lankatilaka. Your knowledgeable guide will talk
                you through the foundations of Buddhism in Sri Lanka, and the
                role it plays today for 75% of the population. Before reaching
                your third and final temple of the experience, you will stop at
                a plantation to take in the sights and smells of how coffee, tea
                and pepper are all grown side by side. Embekka, hosts a Hindu
                shrine unlike many others, and a view of its surroundings that
                is seemingly endless.
              </p>
              <p>
                Upon completion return back to the city and free time to explore
                the Kandy Bazar.
              </p>
              <p>
                <b>Overnight stay at The Radh, Kandy</b>
                <div>
                  <b>Room Category: Deluxe Room</b>
                </div>
              </p>
              <hr />
              <h3>Day 07: Kandy - Ella</h3>
              <p>Meals: B - D</p>
              <p>Breakfast at Hotel and transfer to next destination - Ella</p>
              <p>
                <div>Kandy - Ella</div>
                <div>Travel Distance: 120 km</div>
                <div>Approximate travel time: 04 Hours </div>
              </p>
              <p>Arrive at the bungalow, Rest & Relax</p>
              <p>
                <b>Overnight stay at Nine skies Bungalow</b>
                <div>
                  <b>Room Category: Demodara Room</b>
                </div>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/forts-and-frescos/ravana-ella.jpg"
              alt="Ravana Ella"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 08: Sightseen in Ella</h3>
              <p>Meals: AI</p>
              <p>Breakfast at hotel and proceed to sightseen in Ella</p>
              <p>Approximate excursion time: 03 Hours</p>
              <p>
                Little Adam’s Peak located five minutes away from The Secret
                Ella is Ella’s most popular tourist attraction which resembles
                Adam’s Peak and is a short half hour climb to the top to capture
                panoramic views of an endless valley, tea plantations and
                waterfalls. Ella Rock is a challenging ardent climb not meant
                for the faint hearted that takes about three hours to see
                breath-taking views of the valley.
              </p>
              <p>
                Another historically rich must see location is the Ravana Ella
                Falls which is ranked as one of the widest falls in Sri Lanka.
                The falls form part of the Ravana Ella Wildlife Sanctuary that
                also house the famous Ravana cave known and recorded in history
                as the place where Seetha from India was kidnapped and held
                captive by King Ravana.
              </p>
              <p>
                The majestic Nine Arch Bridge between Ella and Demodara station
                is one of the engineering marvels in the early 20th century.This
                bridge, which is almost 100 years old, has been built with
                blocks of stone and cement without any strengthening iron or
                concrete. The giant arches take the bridge on a curved path to
                link to mountains.
              </p>
              <p>
                <b>Overnight stay at Nine skies Bungalow </b>
                <div>
                  <b>Room Category: Demodara Room</b>
                </div>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        {" "}
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/forts-and-frescos/yala.jpg"
              alt="yala"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 09: Bandarawela – Yala</h3>
              <p>Meals: AI</p>
              <p>Breakfast at Hotel and transfer to Yala.</p>
              <p>
                <div>Bandarawela - Yala</div>
                <div>Travel Distance: 120 km</div>
                <div>Approximate travel time: 03 Hours</div>
              </p>
              <p>Arrive at the Hotel, Rest & Relax</p>
              <p>
                <b>Overnight stay at Chena Huts</b>
                <p>
                  <b>Room Category: Cabin with Pool</b>
                </p>
              </p>
              <hr />
              <h3>Day 10: Yala National Park</h3>
              <p>Meals: AI</p>
              <p>
                Early Morning 05.00 AM proceed to Yala National park for the
                morning Safari with the cup of coffee. After safari return to
                the hotel for the breakfast
              </p>
              <p>
                <b>All Inclusive Includes:</b>
              </p>
              <ul>
                <li>Accommodation</li>
                <li>
                  Morning game drive & evening game drive in customized safari
                  game viewing vehicle
                </li>
                <li>All park entry fees</li>
                <li>Refreshments included on game drives</li>
                <li>Transfers to and from park entrance to resort</li>
                <li>Breakfast, Lunch & dinner at resort</li>
                <li>Water, fruit juices& other soft drinks</li>
                <li>Teas and coffees</li>
                <li>Selected wine, beer and spirits</li>
                <li>Mini Bar (replenished once daily)</li>
                <li>Laundry (6 pieces per cabin per day)</li>
              </ul>
              <p>
                <b>Not included in the All Inclusive:</b>
              </p>
              <ul>
                <li>Telephone Calls</li>
                <li>Safari shop purchases</li>
                <li>
                  Gratuities Champagne, cognacs, fine wines and premium brand
                  spirits
                </li>
              </ul>
              <p>
                Evening proceed on another game drive at the Yala National Park
                <div>Approximate Excursion time: 04 Hours</div>
              </p>
              <p>
                <b>Overnight stay at Chena Huts</b>
                <div>
                  <b>Room Category: Cabin with Pool</b>
                </div>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/forts-and-frescos/galle.jpg"
              alt="galle"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={6}>
            <div className="ps-sm-3 pt-sm-5 pt-2">
              <h3>Day 11: Yala – Galle</h3>
              <p>Meals: B – D</p>
              <p>
                Breakfast at Hotel and transfer to Galle and enjoy a city tour.
              </p>
              <p>
                <div>Yala - Galle</div>
                <div>Travel Distance: 175 km</div>
                <div>Approximate travel time: 04 Hours</div>
                <div>Approximate Excursion time: 02 Hours</div>
              </p>
              <p>
                Galle, the epic Southern capital, is the intersection where
                classic Dutch architecture meets a tropical setting creating a
                vivid atmosphere in beauty. Walk the Dutch-haunted streets
                listening to the creaks of wooden saloon doors and observe how
                European architecture mingles with South Asian traditions today
                flooding cultural enthusiasts on a global scale. Its original
                ramparts and bastions preserved up to date showcase evidences of
                a heritage preserved for more than 3 1/2 centuries
              </p>
              <p>
                <b>Overnight stay at Fort Bazar</b>
                <div>
                  <b>Room Category: Bazar Room</b>
                </div>
              </p>

              <hr />
              <h3>Day 12: Galle – Airport</h3>
              <p>Meals: B - -</p>
              <p>
                After breakfast at the hotel you will leave to the airport in
                time for a departure flight.
              </p>
              <p>
                <div>Galle - Airport</div>
                <div>Travel Distance: 160 km</div>
                <div>Approximate travel time: 02 Hours & 30 Minutes</div>
              </p>
              <h3>End of Services</h3>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FortsAndFrescos;
