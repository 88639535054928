import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Logo from "../../components/logo-black";
//styles
import { Container, Row, Col } from "react-bootstrap";
import { Table } from "reactstrap";
import * as styles from "../../styles/templates/itineraryTemplates.module.scss";

const FloraAndFauna = () => {
  return (
    <div>
      <Container>
        <h3 className="text-center fw-bold">Flora & Fauna by Tripfusion</h3>
        <h3 className="text-center fw-bold">10 Nights / 11 Days</h3>
        <div className="text-center my-md-5 my-2">
          <StaticImage
            src="../../images/itinerary/flora-and-fauna/table.jpg"
            alt="table"
          />

          <p className="mt-2">
            B – Breakfast L – Lunch D – Dinner AI – All Inclusive
          </p>
        </div>

        <h3>Itinerary Highlights</h3>
        <ul>
          <li>Sigiriya Rock</li>
          <li>Minneriya National Park</li>
          <li>Temple of tooth relic</li>
          <li>Peradeniya Botanical gardens</li>
          <li>Polonnaruwa</li>
          <li>Senadiriyagama Village</li>
          <li>Horton Plains</li>
          <li>Yala National Park</li>
          <li>Galle City Tour</li>
        </ul>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/flora-and-fauna/day-1.jpg"
              className="h-100 w-100"
              alt="day-1"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="pt-sm-2 ps-md-3 pt-2">
              <div className="d-md-flex justify-content-end d-none">
                <Logo />
              </div>
              <div className="pt-sm-2  pt-2">
                <h3>Day 01: Airport - Colombo</h3>
                <p>Meals: - - -</p>
                <p>
                  Fly (Destination) to Colombo on (flight #) arriving at (time)
                </p>
                <p>“Ayubowan! Welcome to Sri Lanka”</p>
                <p>
                  On arrival after the immigration process and claiming your
                  luggage our representative along with your designated
                  chauffeur guide, will take you through to the exit and will
                  escort you to the car. You will then be transferred to
                  Colombo.
                </p>
                <p>
                  <div>Airport – Colombo</div>
                  <div>Travel Distance: 36 km</div>
                  <div>Approximate travel time: 45 minutes</div>
                </p>
                <p>
                  Check in at the hotel. Spend the rest of the evening at
                  leisure.
                </p>
                <p>
                  <b>
                    <div>Overnight stay at Uga Residence, Colombo</div>
                    <div>Room Category: Park Suite</div>
                  </b>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/flora-and-fauna/day-2.jpg"
              alt="day-2"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="pt-sm-2 ps-md-3 pt-2">
              <div className="d-md-flex justify-content-end d-none">
                <Logo />
              </div>
              <div className="pt-sm-2 pt-2">
                <div>
                  <h2>Day 02: Colombo – Sigiriya - Minneriya</h2>
                  <p>Meals: B - -</p>
                  <p>
                    Today, after breakfast at the hotel, you will check out and
                    leave to Sigiriya.
                  </p>

                  <p>
                    <div>Colombo – Sigiriya</div>
                    <div>Travel Distance: 195 km</div>
                    <div>Approximate travel time: 4 hours 30 minutes</div>
                  </p>

                  <p className={styles.special}>
                    <i>
                      **Optional: You have the option of chartering a Cinnamon
                      Air flight to Sigiriya which is a 30-minute duration.
                      Thereafter you can drive up to the resort.
                    </i>
                  </p>
                  <p>Check in at the hotel</p>
                  <p>
                    This afternoon you will proceed to experience a jeep safari
                    at the Minneriya National Park.
                  </p>

                  <p>
                    <div>Sigiriya - Minneriya</div>
                    <div>Travel Distance: 15 km</div>
                    <div>Approximate travel time: 20 minutes</div>
                    <div>Approximate excursion time: 3 hours</div>
                  </p>
                  <p>
                    If you're up for a serious rumble in the jungle, then
                    Minneriya is your Coliseum! Spreading over 8800 hectares,
                    this is a sanctuary for elephants. Minneriya is home to
                    herds of deer and ample birdlife, but its spotlight remains
                    between swaying trunks & flapping ears. Park your jeep on
                    the bank of the reservoir and switch off its engine. Watch
                    carefully as herds of elephants - one by one - roll on to
                    the grass-covered banks, to feed on tender shoots. In fact,
                    this is the largest elephant gathering in the world - over
                    700 at a time - which has garnished the park to become a
                    magnet for wildlife enthusiasts across the globe.
                  </p>
                  <p>
                    Upon completion, return to the hotel and spend the rest of
                    the evening at leisure.
                  </p>
                  <p>
                    <b>
                      Overnight stay at Water Garden Resort, Sigiriya
                      <div>Room Category: Deluxe Villa with Pool</div>
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/flora-and-fauna/day-3.jpg"
              alt="day-3"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="pt-sm-2 ps-md-3 pt-2">
              <div className="d-md-flex justify-content-end d-none">
                <Logo />
              </div>
              <div className="pt-sm-2 pt-2">
                <h2>Day 03: Senadiriyagama Village – Polonnaruwa</h2>
                <p>Meals: B L –</p>

                <p>
                  <i>
                    <div>Hotel - Senadirigama Village</div>
                    <div>Travel Distance: 15 km</div>
                    <div>
                      Approximate travel time: 20 minutes & approximate
                      excursion time 3 hours
                    </div>
                  </i>
                </p>
                <p>
                  This morning after breakfast you will set out on an exciting
                  excursion to Senadiriyagama, a beautiful little village
                  located within a 30-minute drive from Sigiriya. Your first
                  visit is to the village temple with a history spanning over
                  300 years. You will be greeted by the village folk with lotus
                  flowers which are customarily believed to represent purity,
                  spiritual awakening and faithfulness. The short tractor ride
                  along the gravel road through the village that leads up to the
                  temple is an experience in itself  as you will see the smiling
                  faces of villagers engaging in their daily routines.
                </p>
                <p>
                  Once you arrive at the temple, you will light oil lamps,
                  incense sticks and offer flowers by the Sacred Bo Tree and
                  stupa. You will then walk towards “Budu Geya” - the main house
                  with Senadhira, a local farmer who will fervently explain the
                  history of the temple and the village relating to stories
                  about how Senadiriyagama temple was once used as a resting
                  camp for troops during the period the land was ruled by the
                  kings.
                </p>
                <p>
                  Thereafter you will walk along the river bund towards the pier
                  to experience a catamaran ride on the Senadiriyagama Lake. If
                  you are lucky, you will have a glimpse of the locals fishing
                  by the lake and maybe even purchase the catch of the day! Once
                  you arrive on the other side of the lake you will take a short
                  walk towards the “Wadiya” - a mud hut, after being served a
                  cool king coconut to beat the hot sun, where a delicious Sri
                  Lankan meal with local delicacies and tropical fruits is
                  prepared for you to indulge in. Upon completion, a waiting
                  tuk-tuk will transport you back to the vehicle.
                </p>
                <p>Afternoon visit Polonnaruwa ruins</p>
                <p>Approximate excursion time: 2-3 hours</p>
                <p>
                  Polonnaruwa - is the 2nd capital of Sri Lanka. Built in the
                  11th and 12th centuries A.D and is a world heritage site. Here
                  you can see the ruins of the Royal Palace, the Gal Viharaya (3
                  splendid statues of the Buddha in upright, sedentary and
                  recumbent postures carved out of rock).
                </p>
                <p>
                  On completion transfer back to Sigiriya – spend the rest of
                  the evening at leisure.
                </p>
                <p>
                  <b>
                    Overnight stay at Water Garden Resort, Sigiriya
                    <div>Room Category: Deluxe Villa with Pool</div>
                  </b>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/flora-and-fauna/day-4.jpg"
              alt="day-4"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="pt-sm-2 ps-md-3 pt-2">
              <div className="d-md-flex justify-content-end d-none">
                <Logo />
              </div>
              <div className="pt-sm-2 pt-2">
                <h2>Day 04: Dambulla - Sigiriya - Kandy</h2>
                <p>Meals: B - -</p>
                <p>
                  Breakfast at the hotel – check out & visit Sigiriya Rock
                  Citadel.
                </p>

                <p>
                  <i>
                    <div>Dambulla – Sigiriya</div>
                    <div>Travel Distance: 18 km</div>
                    <div>Approximate travel time: 30 minutes</div>
                  </i>
                </p>
                <p>
                  After an ancient prince killed his father to claim the throne,
                  he was forced to flee to his pleasure palace and convert it
                  into a fortress to guard against his opposition. The
                  spectacular citadel of Sigiriya built on a giant rock rises
                  sheer and impregnable out of the plains below. This remarkable
                  5th century archaeological site is made unforgettable by its
                  dramatic setting. A moat, rampart, extensive gardens including
                  the renowned water gardens ring the base of the rock and the
                  frescoes of the 'Heavenly Maidens' of Sigiriya. As you
                  descend, you’ll stop for a rest in a shaded location where
                  your porter will have laid out mats and various tasty Sri
                  Lankan delicacies and cool drinks for you to enjoy. This
                  provides much needed respite after the 1200 steps to the
                  summit of the rock.
                </p>
                <p>
                  <i>
                    <div>Sigiriya Rock Fortress</div>
                    <div>Approximate excursion time: 2 hours</div>
                  </i>
                </p>
                <p>
                  <i>
                    <span>
                      <b>Note: </b>
                    </span>
                    Please note that to reach the top of Sigiriya Rock, there
                    are nearly 1,200 stone steps up and then down. Anybody in
                    reasonably good condition will be able to venture to the top
                    of Sigiriya Rock and it's certainly worth the effort.
                  </i>
                </p>
                <p>Thereafter, transfer to Kandy.</p>
                <p>
                  <i>
                    <div>Sigiriya - Kandy</div>
                    <div>Travel Distance: 100 km</div>
                    <div>Approximate travel time: 2 hours & 45 Minutes</div>
                  </i>
                </p>
                <p>Check in at the hotel.</p>
                <p>
                  A scrumptious high tea will be arranged for you this afternoon
                  to be enjoyed overlooking the breathtaking scenery.
                </p>
                <p>
                  W15 has an array of excursions that they offer such as a ride
                  in a vintage land rover, E-bike rides, nature walks which can
                  be arranged at the resort.
                </p>
                <p>
                  Your dedicated butler with attend to all your needs during
                  your stay at W15.
                </p>
                <p>
                  <b>
                    Overnight stay at W15 Hanthana
                    <div>Room Category: Deluxe Room</div>
                  </b>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/flora-and-fauna/day-5.jpg"
              alt="day-5"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="pt-sm-2 ps-md-3 pt-2">
              <div className="d-md-flex justify-content-end d-none">
                <Logo />
              </div>
              <div className="pt-sm-2 pt-2">
                <h2>Day 05: Kandy</h2>
                <p>Meals: B - -</p>
                <p>
                  Breakfast at the hotel. Visit the temple of the tooth relic
                  and enjoy a brief tour of the beautiful Kandy town.
                </p>

                <p>
                  Unconquered for centuries, this capital city held the
                  Sinhalese culture and sacred Buddhist Relics safe against the
                  unsuccessful onslaught of Dutch and Portuguese invasions,
                  before finally falling to British political tricks at the
                  beginning of the nineteenth century. Amongst other fascinating
                  sights, Kandy is the home of The Temple of the Tooth Relic,
                  one of the most sacred places of worship in the Buddhist
                  world.
                </p>
                <p>
                  Program duration: Temple of the Sacred Tooth Relic 1 hour
                  approximately.
                </p>
                <p>
                  On completion proceed to the hotel for your overnight stay.
                </p>
                <p>
                  <b>Highlight 01: Sri Lanka's Drum Village Tour</b>
                </p>
                <p>
                  "Enter the world of the island's traditional drum makers in a
                  remote town in Kandy". The Kandy tour has been structured to
                  provide a unique experience in a typical Sri Lankan village,
                  where drum making has been the traditional vocation of its
                  residents for many decades. The village is situated on a small
                  mountain range and is the pulse of the cultural, musical and
                  religious traditions of this island. Drum production is the
                  main feature of the tour and you will have the opportunity to
                  experience drum production, by exceptionally talented
                  artisans, from scratch to the finished product. In addition, a
                  visit to the village primary school will be insightful to
                  understand the nature and character of the people of the
                  village; Their hospitality and friendliness.
                </p>
                <ul className="list-unstyled">
                  <li>Highlights:</li>
                  <li>
                    - Enter the village that prides itself as the universe of
                    traditional drum making in Sri Lanka; A beautiful indigenous
                    craft unparalleled. Be part of a unique experience, from the
                    first steps of making a drum to the final state of various
                    traditional Sri Lankan drums.
                  </li>
                  <li>
                    - 85 families in the village are committed and dedicated to
                    this drum making tradition.
                  </li>
                  <li>
                    - Experience a typical Sri Lankan meal with homegrown
                    vegetables.
                  </li>
                  <li>
                    - This is an excellent community-based tour led by an
                    experienced guide. In turn, you will contribute to the
                    welfare of the local community.
                  </li>
                </ul>
                <p>
                  <b>
                    Overnight stay at W15 Hanthana
                    <div>Room Category: Deluxe Room</div>
                  </b>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/flora-and-fauna/day-6.jpg"
              alt="day-6"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="pt-sm-2 ps-md-3 pt-2">
              <div className="d-md-flex justify-content-end d-none">
                <Logo />
              </div>
              <div className="pt-sm-2 pt-2">
                <h2>Day 06: Kandy – Nuwara Eliya</h2>
                <p>Meals: B - -</p>
                <p>
                  After breakfast this morning, checkout from the hotel and
                  immerse yourself in history through the three-temple loop and
                  discover fascinating architecture through the process.
                </p>
                <p>
                  Three temples, all offering different stories to capture your
                  imagination. Start at Gadaladeniya and step into the 12th
                  century. In this temple you will find out about the influence
                  that Buddhism has on Hinduism and vice versa. Here notice the
                  grandeur of the architecture, the incredible size of the
                  Buddha statue and the remarkable artistry in the detailing of
                  the artwork. Enjoy a tuk tuk ride through paddy fields and
                  reach the second temple, Lankatilaka. Your knowledgeable guide
                  will talk you through the foundations of Buddhism in Sri
                  Lanka, and the role it plays today for 75% of the population.
                  Before reaching your third and final temple of the experience,
                  you will stop at a plantation to take in the sights and smells
                  of how coffee, tea and pepper are all grown side by side.
                  Embekka, hosts a Hindu shrine unlike many others, and a view
                  of its surroundings that is seemingly endless.
                </p>
                <p>
                  After completion, you will proceed to meet Suzy & Bathiya,
                  proud owners of the Kandyan Manor, a traditional Walauwa
                  (Manor) dating from more nearly two centuries ago, who are
                  currently running a cozy home stay for travelers who seek for
                  some peace and tranquility. They will not only welcome you to
                  their home but will make sure to offer an unforgettable local
                  experience with a cooking demonstration by Suzy.
                </p>
                <p>
                  <b>PREPARATION OF LUNCH-</b> Join the kitchen with Suzy, help
                  to cook lunch. Learn to prepare a complete meal with five
                  curries, cooked on firewood. From scraping the coconut,
                  extracting the milk to pounding of the sambol, using age old
                  tools and methods. Finally, see the preparation of a
                  traditional Sri Lankan Sweet Meat (Halape or Kevum or Kokis or
                  Aluwa) to be enjoyed at evening tea.
                </p>
                <p>Continue your journey to Nuwara Eliya</p>
                <p>
                  <i>
                    <div>Kandy – Nuwara Eliya</div>
                    <div>Travel Distance: 80 km</div>
                    <div>Approximate travel time: 2 hours & 30 Minutes</div>
                  </i>
                </p>
                <p>
                  Check in at the hotel – spend the rest of the evening at
                  leisure.
                </p>
                <p>
                  <b>
                    Overnight stay at Goatfell
                    <div>Room Category: Concordia Bedroom</div>
                  </b>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/flora-and-fauna/day-7.jpg"
              alt="day-7"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="pt-sm-2 ps-md-3 pt-2">
              <div className="d-md-flex justify-content-end d-none">
                <Logo />
              </div>
              <div className="pt-sm-2 pt-2">
                <h2>Day 07 : Nuwara Eliya – Horton Plains</h2>
                <p>Meals: B - -</p>
                <p>
                  This morning after a leisurely breakfast, you will explore the
                  beautiful Nuwara Eliya town.
                </p>
                <p>
                  Also known as the “City of Lights” and “Little England”,
                  Nuwara Eliya was the favorite hill station of the British who
                  tried to create Nuwara Eliya into a typical English Village.
                  The old brick Post office, country house like hill club, with
                  it's hunting pictures, mounted hunting trophies and fish, and
                  its strict formal dinner attire; the 18-hole golf course,
                  racecourse etc., all remind you of 'England'.
                </p>
                <p>
                  Blessed with salubrious climate, breathtaking views of
                  valleys, meadows, mountains and greenery; it's hard to imagine
                  that Nuwara Eliya is only 180 Km from the hot and humid
                  Colombo.
                </p>
                <p>
                  The areas around Nuwara Eliya also provide the main output of
                  tea in Sri Lanka. A trip to the highlands of Sri Lanka is
                  never complete without witnessing the age-old traditional tea
                  plucking and sipping a cup of “Ceylon Tea”. Visitors to Nuwara
                  Eliya can also taste the freshly plucked strawberries and
                  purchase fresh fruits and vegetables.
                </p>
                <p>
                  <b>Optional Excursion:</b>
                </p>
                <p>
                  Leave early morning with packed breakfast to Horton Plains for
                  a trekking excursion.
                </p>
                <p>
                  <i>
                    <div>Nuwara Eliya – Horton Plains</div>
                    <div>Travel Distance: 30 km</div>
                    <div>Approximate travel time: 1 ½ Hrs</div>
                    <div>Approximate Excursion Time: 4 hrs</div>
                  </i>
                </p>
                <p>
                   Horton Plains, UNESCO World Heritage Site & Eco-tourism
                  hideout in the heart of the isle, painted with unending
                  stretches of green pastures marks one of the healthiest wet
                  montane evergreen forests in Sri Lanka. With an altitude above
                  7000ft, the plateau sweeps to a robust 3169 hectares
                  sheltering flora and fauna varieties that may be found nowhere
                  else on earth. As the tributary of Belihul Oya - Bakers Falls
                  - thunders down rocks head off the beaten track - across
                  frost-covered grass - to feel the icy-cold wind against your
                  face. You may even gaze down World's End, a head-spinning
                  3700ft drop towards the Southern end or drive carefully
                  through herds of sambar deer, shy to human touch & birdlife
                  chirping across the plains. If you're cautious enough you may
                  be greeted by the sights of a grunting wild boar, a pouncing
                  fishing cat, otters or even the 'Spotted-Ghost that haunts the
                  plains', the Sri Lankan Leopard!
                </p>
                <p>Return to the hotel upon completion.</p>
                <p>
                  <b>
                    Overnight stay at Goatfell
                    <div>Room Category: Concordia Bedroom</div>
                  </b>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/flora-and-fauna/day-8.jpg"
              alt="day-8"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="pt-sm-2 ps-md-3 pt-2">
              <div className="d-md-flex justify-content-end d-none">
                <Logo />
              </div>
              <div className="pt-sm-2 pt-2">
                <h2>Day 08: Nuwara Eliya – Yala</h2>
                <p>Meals: B L D (All Inclusive)</p>
                <p>After breakfast you will check out & proceed to Yala.</p>
                <p>
                  <i>
                    <div>Nuwara Eliya – Yala</div>
                    <div>Travel Distance: 125km</div>
                    <div>Approximate travel time: 4 hours & 20 minutes</div>
                  </i>
                </p>
                <p>
                  Arrival at the hotel and rest of the day to be spent at
                  leisure.
                </p>

                <p>
                  <b>
                    Overnight stay at Wild Coast Tented Lodges
                    <div>Room Category: Cocoon Suite</div>
                  </b>
                </p>
              </div>

              <div>
                <h2 className="pt-sm-3 pt-2">Day 09: Yala</h2>
                <p>Meals: B L D (All Inclusive)</p>
                <p>
                  This morning you will spend at leisure. At 2:30 pm this
                  afternoon, leave to Yala National Park for a join-in 4WD jeep
                  safari.
                </p>

                <p>
                  Spreading into a vast 1259 Km, Yala - with its glory spinning
                  around vast grasslands, shrubs, tanks and dunes - rightfully
                  is the heartland of wildlife in Sri Lanka. As your jeep rumble
                  through the giant gates of Yala, you are not only stepping
                  into the most visited national park in the country, but also
                  the lands of a civilization which thrived during the reign of
                  Sri Lankan kings. You name it, and Yala has it! Its usual
                  inmates cover herds of elephant, sloth bear, spotted dear,
                  jackal, extensively populated birdlife as well as the highest
                  density of leopards in the world that garnish the park to be
                  one of the finest destinations in the country. Feel the rough
                  track as your jeep thunders over rocks and swish passing
                  narrow bends for an exhilarating experience blended with the
                  cries of wilderness under the tropical sun.
                </p>

                <p>
                  <b>
                    Overnight stay at Wild Coast Tented Lodges
                    <div>Room Category: Cocoon Suite</div>
                  </b>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/flora-and-fauna/day-10.jpg"
              alt="day-10"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="pt-sm-2 ps-md-3 pt-2">
              <div className="d-md-flex justify-content-end d-none">
                <Logo />
              </div>
              <div className="pt-sm-2 pt-2">
                <h2>Day 10: Yala - Galle</h2>
                <p>Meals: B - -</p>
                <p>
                  This morning you will have an early wake-up call at 4:45AM. 
                  You will then depart at dawn for a join-in 4WD jeep wildlife
                  safari into the national park. Among the many wondrous sites
                  and experiences today our main goal: a glimpse of the
                  magnificent yet elusive leopard.
                </p>
                <p>
                  <i>Approximate Excursion time per safari: 04 Hours</i>
                </p>
                <p>
                  Return to the hotel around 10:00 for a full breakfast. Check
                  out and leave to Galle, for your final night in Sri Lanka.
                </p>

                <p>
                  <i>
                    <div>Yala – Galle</div>
                    <div>Travel Distance: 160 km</div>
                    <div>Approximate travel time: 3 hours 15 minutes</div>
                  </i>
                </p>
                <p>
                  Arrival and check in at the hotel and you will proceed on a
                  walking tour of the Galle Dutch Fort with Atheeq.
                </p>
                <p>
                  A budding filmmaker, and a fifth generation member of a
                  prominent gem trading family from Galle, the host wishes to
                  promote an attitude of simplicity through his various efforts.
                  Atheeq comments on the sights that the Galle Fort Walk affords
                  while guiding you through it gently, to supplement the
                  richness of this novel, alternate experience. Simplicity, in
                  the form of maintaining this micro-economy by firmly
                  supporting locals, their ideas, and their businesses, is one
                  aspect that he wants to reinforce through this walk. Towards
                  this end, it helps that even as young boy he got up to plenty
                  of mischief within the fort’s immediate environs and,
                  therefore, allowed him to form family-like bonds with many of
                  its inhabitants. Also, he expends much energy in improving
                  sustainable business practices within the vibrant Fort
                  community and in promoting local culture to those who partake
                  in the Galle Fort Walk. His message of simplicity and
                  contentedness can be discerned in the few hours that you share
                  with him/he shares with you. It is refreshing, and it
                  punctuates the historical and cultural leaning this experience
                  takes.
                </p>
                <p>Rest of the evening to be spent at leisure.</p>

                <p>
                  <b>
                    Overnight stay at Amangalla Resort. Galle
                    <div>Room Category: Bedroom</div>
                  </b>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src="../../images/itinerary/flora-and-fauna/day-11.jpg"
              alt="day-11"
              className="h-100 w-100"
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="pt-sm-2 ps-md-3 pt-2">
              <div className="d-md-flex justify-content-end d-none">
                <Logo />
              </div>
              <div className="pt-sm-2 pt-2">
                <h2>Day 11: Galle - Airport</h2>
                <p>Meals: B - -</p>
                <p>
                  Today you will leave to the airport in time for a departure
                  flight.
                </p>
                <p>
                  <i>
                    <div>Galle - Airport</div>
                    <div>Travel Distance: 220 km </div>
                    <div>Approximate travel time: 2 hours & 30 minutes</div>
                  </i>
                </p>
                <h3>End of Services</h3>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FloraAndFauna;
