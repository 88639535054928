import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

//styles
import { Container, Row, Col } from 'react-bootstrap'
import * as styles from '../../styles/templates/itineraryTemplates.module.scss'

const ShanthiAndPrana = () => {
  return (
    <div className={styles.main}>
      <Container>
        <h3 className='text-center'>Shanthi & Prana by Tripfusion</h3>
        <h3 className='text-center'>08 Nights / 09 Days</h3>
        <div className='text-center my-md-5 my-2'>
          <StaticImage
            src='../../images/itinerary/shanthi-and-prana/table.jpg'
            alt='table'
          />

          <p className='mt-2'>
            B – Breakfast L – Lunch D – Dinner AI – All Inclusive
          </p>
        </div>
        <h3>Itinerary Highlights</h3>
        <ul>
          <li>Spa Treatment at Wattura Resort</li>
          <li>Wellness Program at Anasa Resort</li>
          <li>Colombo City Tour</li>
        </ul>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src='../../images/itinerary/shanthi-and-prana/day-1.jpg'
              alt='Day1'
              className='h-100 w-100'
            />
          </Col>
          <Col sm={12} md={6}>
            <div className='ps-sm-3 mt-sm-5 mt-2'>
              <h3>Day 01: Airport - Waikkal</h3>
              <p>Meals: - L D</p>
              <p>Ayubowan! Welcome to Sri Lanka</p>
              <p>
                On arrival as you exit the aircraft, a Trip Fusion
                representative will welcome you and escort you to the lounge
                after immigration process, where you can freshen up and enjoy a
                quick cup of tea while he/she claims your luggage. Our
                representative along with the designated chauffeur guide will
                then take you through to the exit and will escort you to the
                car. You will then be transferred to your hotel in Waikkal for
                check-in and a well-deserved rest after your long journey.
              </p>
              <p>
                <div>Airport – Waikkal</div>
                <div>Travel Distance: 20 km</div>
                <div>Approximate travel time: 40 Minutes</div>
              </p>
              <p>
                <b>
                  <div>Overnight stay at Wattura, Waikkal</div>
                  <div>Room Category: Suite</div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src='../../images/itinerary/shanthi-and-prana/day-2-3.jpg'
              alt='Day2&3'
              className='h-100 w-100'
            />
          </Col>
          <Col sm={12} md={6}>
            <div className='ps-sm-3 mt-sm-5 mt-2'>
              <h3>Day 02 & 03: Day at the Spa</h3>
              <p>Meals: B L D</p>
              <p>
                Breakfast at Hotel and day spent at the SPA nourishing your body
                and its energy with a combination of carefully curated Spa
                rituals and treatments.
              </p>
              <p>
                You can select the preferred spa treatment and pay directly to
                the hotel, or we can pre- book as per your choice.
              </p>
              <p>Lunch and Dinner at the Hotel</p>

              <p>
                <b>
                  <div>Overnight stay at Wattura, Waikkal</div>
                  <div>Room Category: Suite</div>
                </b>
              </p>

              <h3>Day 04: Waikkal - Bandarawela</h3>
              <p>Meals: AI</p>
              <p>
                This morning after a scrumptious breakfast you will leave to
                Bandarawela
              </p>

              <p>
                <div>Waikkal – Bandarawela</div>
                <div>Travel Distance: 220 km</div>
                <div>Approximate travel time: 06 Hours</div>
              </p>
              <p>Arrive at the Hotel, Relax & unwind after your long journey</p>
              <p>
                <b>
                  <div>Overnight stay at Anasa Wellness Spa</div>
                  <div>Room Category: Deluxe</div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src='../../images/itinerary/shanthi-and-prana/day-5-6-7.jpg'
              alt='Day5,6&7'
              className='h-100 w-100'
            />
          </Col>
          <Col sm={12} md={6}>
            <div className='ps-sm-3 mt-sm-5 mt-2'>
              <h3>Day 05, 06 & 07: Wellness Program</h3>
              <p>Meals: AI</p>
              <p>
                Next 03 day spent immersing yourself in healing and wellness.
              </p>
              <p>
                You are entitled to select a wellness package that suits you
                best (for 03 days) on complimentary basis.
              </p>

              <p>
                <b>
                  <div>Overnight stay at Anasa Wellness Spa</div>
                  <div>Room Category: Deluxe</div>
                </b>
              </p>

              <h3>Day 08: Bandarawela – Colombo</h3>
              <p>Meals: B - -</p>
              <p>
                Breakfast at Hotel and transfer to Colombo and enjoy a city tour
                exploring the Capital City.
              </p>

              <p>
                <div>Bandarawela - Colombo</div>
                <div>Travel Distance: 200 km</div>
                <div>Approximate travel time: 05 Hours and 30 Minutes</div>
                <div>Approximate Excursion time: 02 Hours</div>
              </p>
              <p>
                Enjoy a city tour of Colombo which is the largest city and
                former administrative capital of Sri Lanka and is a busy and
                vibrant city with a mixture of modern life and remnants of a
                bygone colonial era. Due to its large harbour and its strategic
                position along the East-West sea trade routes, Colombo was known
                to ancient traders 2,000 years ago. However it was only made the
                Capital of the island when Sri Lanka was ceded to the British
                Empire in 1815. The main city is home to a majority of the Sri
                Lanka’s corporate offices
              </p>
              <p>
                <b>
                  <div>Overnight stay at Uga Residence, Colombo</div>
                  <div>Room Category: Park Suite</div>
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <StaticImage
              src='../../images/itinerary/shanthi-and-prana/day-9.jpg'
              alt='Day9'
              className='h-100 w-100'
            />
          </Col>
          <Col sm={12} md={6}>
            <div className='ps-sm-3 mt-sm-5 mt-2'>
              <h3>Day 09: Colombo – Airport</h3>
              <p>Meals: B - -</p>
              <p>
                After breakfast at the hotel you will leave to the airport in
                time for a departure flight.
              </p>

              <p>
                <div>Colombo - Airport</div>
                <div>Travel Distance: 35 km</div>
                <div>Approximate travel time: 40 Minutes</div>
              </p>

              <h3>End of Services</h3>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ShanthiAndPrana
